/* ========================================================================
 * DOM-based Routing
 * Based on http://goo.gl/EUTi53 by Paul Irish
 *
 * Only fires on body classes that match. If a body class contains a dash,
 * replace the dash with an underscore when adding it to the object below.
 *
 * .noConflict()
 * The routing is enclosed within an anonymous function so that you can
 * always reference jQuery with $, even when in .noConflict() mode.
 * ======================================================================== */

(function ($) {

	// Use this variable to set up the common and page specific functions. If you
	// rename this variable, you will also need to rename the namespace below.
	var Sage = {
		// All pages
		'common': {
			init: function () {
				window.PageFunctions.smoothScroll();
				
				// ----- mobile start
				$(".h-mobile-trigger").click(function(e){
					e.preventDefault();
					$('.h-mobile-trigger').toggleClass('open');
					$('.h-header--mobile').toggleClass('open');
					$('.h-header--mobile-off').toggleClass('open');
					// $('body').toggleClass('custom-overflow');
				});

				$(".h-header--mobile--close, .h-header--mobile-off").click(function(e){
					e.preventDefault();
					$('.h-mobile-trigger').removeClass('open');
					$('.h-header--mobile').removeClass('open');
					$('.h-header--mobile-off').removeClass('open');
				});		
				// ----- mobile end

				AOS.init({
					delay: 100,
					duration: 750,
					offset: 300,
					disable: function () {
						var maxWidth = 900;
						return window.innerWidth < maxWidth;
					}
				});

				$('.gfield_select').select2();

				// ----- portal loading animation
				$('.page-template-template-portal').addClass('loading-init');
				// ----- portal loading animation
				
				// ----- faqs block js
				$('.h-faq--question').on('click',function(){
					$(this).toggleClass('active');
					$(this).siblings('.h-faq--answer').slideToggle(300);
					// ----- faqs block js
				});

				// ----- partners block Slick
				$('.h-partners--logos').slick({
					'arrows' : true,
					'dots' : false,
					'autoplay' : false,
					'fade' : false,
					'draggable' : true,
					'speed' : 600,
					'responsive' : [
						{
							'breakpoint' : 899,
							'settings' : {
								'arrows' : false,
								'dots' : true
							}
						}
					]
				});
				// ----- partners block Slick

				// ----- single post Slick
				$('.h-single--slideshow').slick({
					'arrows' : true,
					'dots' : false,
					'autoplay' : false,
					'fade' : true,
					'draggable' : true,
					'speed' : 600
				});
				// ----- single post Slick

				// ----- cookie js
				function createCookie(name, value, days) {
					var expires;
					if (days) {
						var date = new Date();
						date.setTime(date.getTime() + (days * 24 * 60 * 60 * 1000));
						expires = "; expires=" + date.toGMTString();
					}
					else {
						expires = "";
					}
					document.cookie = name + "=" + value + expires + "; path=/";
				}
				function getCookie(name) {
					var nameEQ = name + "=";
					var ca = document.cookie.split(';');
					for(var i=0;i < ca.length;i++) {
						var c = ca[i];
						while (c.charAt(0)===' ') c = c.substring(1,c.length);
						if (c.indexOf(nameEQ) === 0) return c.substring(nameEQ.length,c.length);
					}
					return null;
				}
				var checkCookie = getCookie('cookieNotify');
				if ( checkCookie !== 'dismissed' ) {
					$('.h-cookie').addClass('active'); 
				}
				$('.h-cookie--accept').on('click',function(){
					$('.h-cookie').removeClass('active'); 
					createCookie('cookieNotify','dismissed', 365);
				});
				// ----- cookie js	

				// ----- fixed menu js
				$('.h-fixed-menu').addClass('active');
				// ----- fixed menu js

				// ----- flyout form js
				$('.menu-contact').on('click',function(e){
					e.preventDefault();
					$('.menu-contact').toggleClass('current-menu-item');
					$('.h-fly-out').toggleClass('active');
					$('.h-fly-off').toggleClass('active');
				});

				$('.h-fly-out--close, .h-fly-off').on('click',function(){
					$('.h-fly-out').removeClass('active');
					$('.h-fly-off').removeClass('active');
					$('.menu-contact').removeClass('current-menu-item');
				});
				// ----- flyout form js

				// ----- expanding Gravity Form textarea
				$('.gfield.expands').on('click',function(){
					$(this).addClass('expanded');
				});
				// ----- expanding Gravity Form textarea
			},
			finalize: function () {
				// JavaScript to be fired on all pages, after page specific JS is fired
			}
		},
		// Home page
		'portal': {
			init: function () {
				// JavaScript to be fired on the portal page
				// $('.h-loading').addClass('loading-init');
			},
			finalize: function () {
				// JavaScript to be fired on the home page, after the init JS
			}
		},
		// About us page, note the change from about-us to about_us.
		'about_us': {
			init: function () {
				// JavaScript to be fired on the about us page
			}
		}
	};

	// The routing fires all common scripts, followed by the page specific scripts.
	// Add additional events for more control over timing e.g. a finalize event
	var UTIL = {
		fire: function (func, funcname, args) {
			var fire;
			var namespace = Sage;
			funcname = (funcname === undefined) ? 'init' : funcname;
			fire = func !== '';
			fire = fire && namespace[func];
			fire = fire && typeof namespace[func][funcname] === 'function';

			if (fire) {
				namespace[func][funcname](args);
			}
		},
		loadEvents: function () {
			// Fire common init JS
			UTIL.fire('common');

			// Fire page-specific init JS, and then finalize JS
			$.each(document.body.className.replace(/-/g, '_').split(/\s+/), function (i, classnm) {
				UTIL.fire(classnm);
				UTIL.fire(classnm, 'finalize');
			});

			// Fire common finalize JS
			UTIL.fire('common', 'finalize');
		}
	};

	// Load Events
	$(document).ready(UTIL.loadEvents);

})(jQuery); // Fully reference jQuery after this point.

